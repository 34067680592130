import classNames from "classnames"
import React, { useRef } from "react"
import type { SwiperRef } from "swiper/react"
import { Swiper, SwiperSlide } from "swiper/react"

import { Analytics } from "@bounce/analytics"
import { useTranslation } from "@bounce/i18n"
import { Button, Link, SliderNavigation } from "@bounce/web-components"

import { useReviews } from "./useReviews"
import { FeatureFlag, useFeatureFlag } from "../../../../config/flags"
import { useSectionViewed } from "../../../../hooks/useSectionViewed"
import { InteractionEvents } from "../../../../libs/analytics/events"
import { Product } from "../../../../types"
import { goToApp } from "../../../../utils/appURL"
import type { Review } from "../../../../utils/reviews"
import { ReviewCard } from "../ReviewCard/ReviewCard"

export type ReviewsSliderProps = {
  cityId?: string
  className?: string
  description?: string
  disableAvatarBlueFilter?: boolean
  minimumReviewsCount?: number
  name: string
  product?: Product
  reviews?: Review[]
  showAppReviews?: boolean
  showSeeAllReviews?: boolean
  cityName?: string
  title?: string
  titleAs?: "h2" | "h3"
}

export const ReviewsSlider = ({
  cityId,
  className,
  description,
  disableAvatarBlueFilter = false,
  minimumReviewsCount = 1,
  name,
  cityName,
  product = Product.LuggageStorage,
  reviews: reviewsProp,
  showAppReviews = false,
  showSeeAllReviews,
  title,
  titleAs: TitleAs = "h2",
}: ReviewsSliderProps) => {
  const [t] = useTranslation()
  const reviewsHook = useReviews({ product, showAppReviews })
  const swiperRef = useRef<SwiperRef>(null)
  const { sectionRef } = useSectionViewed({ name })

  const [newReviewCardEnabled] = useFeatureFlag(FeatureFlag.NewReviewSection)
  const useReviewV2 = newReviewCardEnabled && product === Product.LuggageStorage
  const reviews = !!reviewsProp?.length && reviewsProp.length >= minimumReviewsCount ? reviewsProp : reviewsHook

  const next = () => {
    swiperRef.current?.swiper.slideNext()
    Analytics.track(InteractionEvents.NextReviewPressed)
  }

  const prev = () => {
    swiperRef.current?.swiper.slidePrev()
  }

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.preventDefault()
    const href = ev.currentTarget.href

    goToApp(href, { cta: name })
  }

  const onClickReviewCard = (reviewer: string) => {
    Analytics.track(InteractionEvents.ReviewCardPressed, { reviewer })
  }

  const headline = newReviewCardEnabled
    ? title || t("cmp.reviewsSlider.millionsOfCustomers1", "Millions of customers all over the world")
    : title || t("cmp.reviewsSlider.whatOurCustomersSayV5", "Our customers say it best")

  const subHeader = newReviewCardEnabled
    ? description || t("cmp.reviewsSlider.millionsOfBagsStored2", "Our customers say it best.")
    : description || t("cmp.reviewsSlider.descriptionV4", "Millions of bags stored. 1M+ customers all over the world.")

  return (
    <section
      ref={sectionRef}
      className={classNames("relative mx-auto flex w-full max-w-screen-xl flex-col", className)}>
      <TitleAs className="title2 px-6 text-black">{headline}</TitleAs>
      <p className="subtitle mt-2 px-6 text-neutral-800 md:mt-2.5 xl:mt-3">{subHeader}</p>
      <div className="mt-14 md:mt-16">
        <Swiper
          ref={swiperRef}
          slidesPerView="auto"
          spaceBetween={useReviewV2 ? 32 : 18}
          slidesOffsetBefore={18}
          slidesOffsetAfter={24}
          watchSlidesProgress
          rewind>
          {reviews.map(review => (
            <SwiperSlide
              key={review.id}
              className={
                useReviewV2
                  ? "!h-auto max-w-[284px] md:!min-w-[230px] md:!max-w-[340px]"
                  : "!h-auto !w-[340px] md:!w-[380px] xl:!w-[420px]"
              }
              onClick={() => onClickReviewCard(review.user.fullName)}>
              <ReviewCard
                review={review}
                withCity
                useReviewV2={useReviewV2}
                className={
                  useReviewV2 ? "h-full max-w-[284px] md:max-w-[340px]" : "h-full w-[340px] md:w-[380px] xl:w-[420px]"
                }
                avatarClassName={disableAvatarBlueFilter || useReviewV2 ? "" : "[filter:url(#blue-filter)]"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={classNames(
            "mt-7 flex w-full flex-row items-center justify-end px-6 md:mt-12",
            showSeeAllReviews && "justify-between pl-0",
          )}>
          {showSeeAllReviews && (
            <Button
              as={Link}
              onClick={onClick}
              className="m-0 !pr-2 text-primary-700 xs:px-5"
              href={{
                pathname: "/reviews",
                query: { cityId },
              }}
              title={t("cmp.reviewsSlider.luggageStorageInCity", "Luggage Storage in {{cityName}}", { cityName })}
              variant="ghost">
              {t("cmp.reviewsSlider.seeAllReviews", "See all reviews")}
            </Button>
          )}
          <SliderNavigation onClickNext={next} onClickPrev={prev} />
        </div>
      </div>
    </section>
  )
}
