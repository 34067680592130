import classNames from "classnames"
import React from "react"

import type { ReviewMetaDataProps } from "./ReviewMetaData"
import { ReviewMetaData } from "./ReviewMetaData"

export type ReviewProps = {
  feedback?: string | React.ReactNode
  className?: string
} & ReviewMetaDataProps

export const Review = ({ feedback, className, ...metaDataProps }: ReviewProps) => (
  <div className={classNames("review", className)}>
    <ReviewMetaData {...metaDataProps} />
    {!!feedback && <p className="review__feedback">{feedback}</p>}
  </div>
)
