import classNames from "classnames"
import type { ImageProps } from "next/image"
import React from "react"

import { Avatar } from "../Avatar/Avatar"
import { RatingStars } from "../RatingStars/RatingStars"

export type ReviewMetaDataProps = {
  avatarAlt?: string
  avatarClassName?: string
  avatarSrc?: ImageProps["src"]
  className?: string
  metaData?: string | React.ReactNode
  name?: string
  rating: number
  withAvatar?: boolean
}

export const ReviewMetaData = ({
  avatarAlt = "",
  avatarClassName = "",
  avatarSrc,
  className,
  metaData,
  name = "",
  rating,
  withAvatar = true,
}: ReviewMetaDataProps) => (
  <div className={classNames("review__header", className)}>
    {withAvatar && (
      <Avatar
        name={name}
        src={avatarSrc}
        alt={avatarAlt}
        loading="lazy"
        className={classNames("!size-16", avatarClassName)}
      />
    )}
    <div className="flex flex-1 flex-col">
      <span className="label2">{name}</span>
      {!!metaData && (
        <span className="review__metadata" data-chromatic="ignore" suppressHydrationWarning>
          {metaData}
        </span>
      )}
      <RatingStars ratingScore={rating} max={5} className={metaData ? "mt-1" : undefined} />
    </div>
  </div>
)
