import classNames from "classnames"
import React, { useMemo } from "react"

import { COUNTRIES, useTranslation } from "@bounce/i18n"
import { Review } from "@bounce/web-components"

import type { Review as TReview } from "../../../../utils/reviews"
import { ReviewV2 } from "../ReviewV2/ReviewV2"

export type ReviewCardProps = {
  review: TReview
  withCity?: boolean
  className?: string
  useReviewV2?: boolean
  avatarClassName?: string
}

export const ReviewCard = ({
  review,
  withCity = false,
  className,
  avatarClassName = "",
  useReviewV2 = false,
}: ReviewCardProps) => {
  const [t] = useTranslation()
  const name = review.user.fullName || t("cmp.reviewCardNext.anonymous", "Anonymous")
  const emoji = review.country ? COUNTRIES.find(country => country.name === review.country)?.emoji : ""

  const metaData = useMemo(() => {
    if (withCity || review.country) {
      const location = withCity ? review.city.translatedName : review.country

      return useReviewV2
        ? `${t("cmp.reviewCardNext.reviewLocation", "Bounced in {location}", { location })} ${emoji}`
        : `BOUNCED: ${location}`
    }
    return undefined
  }, [review, withCity, useReviewV2, t, emoji])

  return (
    <>
      {useReviewV2 ? (
        <ReviewV2
          className={classNames("rounded-lg border-2 border-neutral-300 px-5 py-4 xl:px-8 xl:py-6", className)}
          feedback={review.translatedFeedback ?? review.feedback}
          name={name}
          rating={review.rating}
          avatarSrc={review.user.image}
          avatarClassName={avatarClassName}
          metaData={metaData}
        />
      ) : (
        <Review
          className={classNames("rounded-lg border-2 border-neutral-300 px-5 py-4 xl:px-8 xl:py-6", className)}
          feedback={review.translatedFeedback ?? review.feedback}
          name={name}
          rating={review.rating}
          avatarSrc={review.user.image}
          avatarClassName={avatarClassName}
          metaData={metaData}
        />
      )}
    </>
  )
}
